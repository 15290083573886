import { useDripsyTheme, View } from 'dripsy'
import React from 'react'
import { Platform, TextStyle } from 'react-native'
import PickerPopup from 'react-native-picker-select'
import type { SxStyleProp } from '@theme-ui/core'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import useTheme from '@beatgig/theme/use-theme'
import { useFont } from '@beatgig/theme/use-font'

type Props = {
  sx?: SxStyleProp
  webPickerStyle?: TextStyle
} & React.ComponentProps<typeof PickerPopup>

export default function Picker(props: Props) {
  const {
    items,
    onValueChange,
    value,
    placeholder = {
      label: 'Any',
      value: null,
    },
    sx,
    webPickerStyle = {},
    ...rest
  } = props
  const { colors, space, radii, borderWidths } = useTheme()
  const { body } = useFont()

  return (
    <View sx={sx}>
      <PickerPopup
        placeholder={placeholder}
        {...rest}
        items={items}
        onValueChange={(value, index) => {
          console.log('picker pop-up onchange', { value, index })
          if (value === (placeholder as any)?.label) {
            onValueChange('', -1)
          } else {
            onValueChange(value, index)
          }
        }}
        itemKey="value"
        style={{
          inputIOS: {
            fontSize: 16,
            paddingVertical: 12,
            paddingHorizontal: 10,
            borderWidth: 1,
            borderColor: colors?.mutedText as string,
            borderRadius: radii[4],
            color: colors?.text,
            backgroundColor: colors?.background,
            paddingRight: 30, // to ensure the text is never behind the icon
          },
          inputAndroid: {
            fontSize: 16,
            paddingHorizontal: 10,
            paddingVertical: 8,
            borderWidth: 0.5,
            borderColor: colors?.mutedText as string,
            borderRadius: radii[4],
            color: colors?.text,
            backgroundColor: colors?.background,
            paddingRight: 30, // to ensure the text is never behind the icon
          },
        }}
        // key={`picker-${toOrFrom}`}
        value={value}
        pickerProps={{
          // @ts-ignore
          style: {
            borderStyle: 'solid',
            // borderTopWidth: 1,
            // borderBottomWidth: 1,
            ...Platform.select({
              web: {
                // fontSize: '100%',
                appearance: 'none',
                backgroundColor: colors?.background,
                color: colors?.text,
                borderColor: colors?.mutedText,
                textAlign: 'center',
                padding: space?.[2],
                borderRadius: radii?.[4],
                borderWidth: borderWidths?.[1],
                textTransform: 'capitalize',
                fontFamily: body.default,
                fontSize: 16,
                paddingRight: space?.[3] + space?.[2],
                ...webPickerStyle,
              },
              default: {},
            }),
          },
          itemStyle: {
            textAlign: 'center',
            // color: colors?.text,
            ...Platform.select({
              web: {
                appearance: 'none',
                backgroundColor: 'transparent',
                // padding: space[3],
              },
              default: {},
            }),
          },
        }}
      />
      <View
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          top: 0,
          pr: 2,
          justifyContent: 'center',
        }}
        pointerEvents="none"
      >
        <Ionicons name="chevron-down" color={colors?.text} size={18} />
      </View>
    </View>
  )
}
